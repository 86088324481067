import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { FaInstagram, FaTwitter, FaFacebookSquare, FaYoutube} from 'react-icons/fa';
import styled from '@emotion/styled';
import tw from 'tailwind.macro';

import PaidFor from './PaidFor'

 const Wrapper = styled.footer`
  ${tw`
    py-12 px-12 flex flex-wrap border-t-4 border-lightBlue bg-darkBlue
  `}
  min-height: 250px;
 `;

const Column = styled.div`
  ${tw`
    w-full lg:w-1/3 h-100
  `}
`;

const LogoColumn = styled(Column)`
  ${tw`
    mx-auto max-w-sm mb-8 lg:mb-0 self-end
  `}
`;

const MessageColumn = styled(Column)`
  ${tw`
    flex flex-col justify-between items-center text-center text-white text-base
  `}
`;

const LinkColumn = styled(Column)`
  ${tw`
    self-end px-8 mt-8 text-center
    lg:text-left lg:mt-0
  `}
`;

const FooterLink = styled.a`
  ${tw`
    block text-white hover:text-lightBlue
  `}
`;

const StyledLink = styled(Link)`
  ${tw`
    block text-white hover:text-lightBlue
  `}
`;

const SocialLinks = styled.div`
  ${tw`
    mt-4
    flex justify-center
    text-3xl
    lg:mt-1 lg:justify-start
  `}
  &>a {
    ${tw`
      mr-2
    `}
  }
`

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      logoImage: file(relativePath: { eq: "JO_logo_full_white.png" }) {
        childImageSharp {
          fluid(maxWidth: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
      <Wrapper>
          <LogoColumn>
            <Img fluid={data.logoImage.childImageSharp.fluid} alt="Joel O'Dorisio for Ohio State Senate 2020" />
          </LogoColumn>
          <MessageColumn>
            <p>Checks can be made to The Committee to Elect Joel O'Dorisio and mailed to 1001 Napoleon Rd, Bowling Green OH 43402</p>
            <PaidFor />
          </MessageColumn>
          <LinkColumn>
            <StyledLink to="/">Home</StyledLink>
            <StyledLink to="/issues">Issues</StyledLink>
            <FooterLink href="https://secure.actblue.com/donate/committee-to-elect-joel-o-dorisio-1">Donate</FooterLink>
            {/* <StyledLink to="/take-action">Take Action</StyledLink> */}
            <StyledLink to="/covid-19-resources">COVID-19 Resources</StyledLink>
            <FooterLink href="mailto:joelodo1@gmail.com">Contact</FooterLink>
            <SocialLinks>
              <FooterLink href="https://twitter.com/joel4ohio" title="Twitter">
                <FaTwitter/>
              </FooterLink>
              <FooterLink href="https://instagram.com/joel4ohio/" title="Instagram">
                <FaInstagram/>
              </FooterLink>
              <FooterLink href="https://facebook.com/joel4ohio" title="Facebook">
                <FaFacebookSquare/>
              </FooterLink>
              <FooterLink href="https://www.youtube.com/channel/UCupexjwunw1SuG7Dxo5KwfQ" title="YouTube">
                <FaYoutube/>
              </FooterLink>
            </SocialLinks>
          </LinkColumn>
      </Wrapper>
  )
}

export default Footer
