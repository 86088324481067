import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { FaInstagram, FaTwitter, FaFacebookSquare, FaYoutube} from 'react-icons/fa';
import styled from '@emotion/styled';
import tw from 'tailwind.macro';
import { useSpring, animated } from "react-spring";

const PageLink = styled(Link)`
  ${tw`
    block mt-4 text-lg text-white  relative
    lg:inline-block lg:mt-0 lg:mr-4
  `}
  &:after {
    content: '';
    display: block;
    border-bottom: 3px solid #00adef;
    width: 0;
    position: absolute;
    left: 0;
    -webkit-transition: .2s ease;
    transition: .2s ease;
  }
  &:hover:after { 
    width: 100%; 
  }
`

const FancyButton = styled.a`
  ${tw`
    inline-block text-base px-6 py-4 leading-none rounded-full bg-lightBlue text-white  mx-2 
    lg:mt-0
  `}
  transform: scale(1);
  transition: transform .1s ease-out;
  will-change: transform;
  &:hover {
    will-change:transform;
    transform: scale(1.05);
    transition: transform .1s ease-out;
  }
  
`;

const SocialLink = styled.a`
  ${tw`
    inline-block text-white mr-2 text-2xl
    hover:text-darkBlue 
  `}
  &:last-of-type{
    ${tw`
      mr-4
    `}
  }
`;

const EyebrowNav = styled.div`
  ${tw`
    hidden
    lg:flex
    h-8 px-6 bg-lightBlue text-white w-100 justify-end items-center
  `}
  line-height:1;
`;

const SocialLinks = styled.div`
  ${tw`
    flex items-center 
    lg:hidden
  `}

  a{
    ${tw`
      mx-2 text-4xl
    `}
  }

  a:hover{
    ${tw`
      text-lightBlue
    `}
  }

  a:last-of-type{
    ${tw`
      mr-2
    `}
  }
`;

function getWindow() {
  if(typeof window !== 'undefined'){
    return window;
  } else {
    return {
      innerWidth: null, innerHeight: null
    };
  }
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = getWindow();
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}



function Navigation () {
  const { width } = useWindowDimensions();
  const [navOpen, toggleNavOpen] = useState(width >= 1024 ? true : false);

  const navAnimation = useSpring({
    opacity: navOpen ? 1 : 0,
    transform: navOpen ? `translateY(0)` : `translateY(-100%)`,
    height: navOpen ? "auto" : 0,
  })

  // force nav open on desktop
  if (width >= 1024 && !navOpen){
    toggleNavOpen(true); 
  }

  return (
    <div>
      <EyebrowNav>

          <SocialLink href="https://twitter.com/joel4ohio" title="Twitter">
            <FaTwitter/>
          </SocialLink>
          <SocialLink href="https://instagram.com/joel4ohio/" title="Instagram">
            <FaInstagram/>
          </SocialLink>
          <SocialLink href="https://facebook.com/joel4ohio" title="Facebook">
            <FaFacebookSquare/>
          </SocialLink>

      </EyebrowNav>
      <nav className="flex items-center justify-between flex-wrap bg-darkBlue p-6 border-b-4 border-lightBlue">
        
        {/* Logo */}
          <Link to="/" className="flex items-center flex-shrink-0 text-white mr-6">
            <svg className="fill-current h-12 w-12 mr-4" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 65 65"><path style={{fill:"#fff"}} d="M305.86,369.67a32.5,32.5,0,1,0,32.5,32.5A32.5,32.5,0,0,0,305.86,369.67Zm0,56.42a23.92,23.92,0,1,1,23.92-23.92A23.92,23.92,0,0,1,305.86,426.09Z" transform="translate(-273.36 -369.67)"/><path style={{fill:"#fff"}} d="M305.86,382.05A20.12,20.12,0,1,0,326,402.17,20.12,20.12,0,0,0,305.86,382.05Zm0,39a18.92,18.92,0,1,1,18.92-18.92A18.92,18.92,0,0,1,305.86,421.09Z" transform="translate(-273.36 -369.67)"/><path style={{fill:"#fff"}} d="M305.86,417.19a15,15,0,0,0,0-30Z" transform="translate(-273.36 -369.67)"/></svg>
            <span className="font-display font-black text-2xl md:text-3xl">Joel O&rsquo;Dorisio</span>
          </Link>
        {/* Mobile Nav Button Toggle */}
        <div className="cursor-pointer relative z-50 lg:hidden block" >
          <button 
            className="flex items-center px-3 py-2 border rounded text-white border-white hover:text-darkBlue hover:bg-white" 
            onClick={() => toggleNavOpen(!navOpen)}
            >
            <svg className="fill-current h-5 w-5" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
          </button>
        </div>
        {/* Navigation Link List */}
        <animated.div className={`w-full flex-grow text-center justify-center items-center lg:h-auto flex lg:flex-row lg:justify-end lg:items-center lg:w-auto flex-col align-end`} style={navAnimation}>
          <div className="text-sm lg:self-auto">
            <PageLink to="/issues">
              Issues
            </PageLink>
            <PageLink to="/about">
              About
            </PageLink>
            {/* <PageLink to="/take-action">
              Take Action
            </PageLink> */}
          </div>
          <div className={"my-4 lg:self-auto lg:my-0"}>
            <FancyButton href="https://secure.actblue.com/donate/committee-to-elect-joel-o-dorisio-1">
              Donate
            </FancyButton>
          </div>

          <SocialLinks>
            
            <SocialLink href="https://twitter.com/joel4ohio" title="Twitter">
              <FaTwitter/>
            </SocialLink>
            <SocialLink href="https://instagram.com/joel4ohio/" title="Instagram">
              <FaInstagram/>
            </SocialLink>
            <SocialLink href="https://facebook.com/joel4ohio" title="Facebook">
              <FaFacebookSquare/>
            </SocialLink>
            <SocialLink href="https://www.youtube.com/channel/UCupexjwunw1SuG7Dxo5KwfQ" title="YouTube">
              <FaYoutube/>
            </SocialLink>
          </SocialLinks>
          
        </animated.div>
      </nav>
    </div>
  )
}

Navigation.propTypes = {
  siteTitle: PropTypes.string,
}

Navigation.defaultProps = {
  siteTitle: ``,
}

export default Navigation
