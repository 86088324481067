import React from 'react';
import tw from 'tailwind.macro';
import "../utils/globals.css";
import styled from '@emotion/styled';
import Header from './header';
import Footer from './footer';
// import { library } from '@fortawesome/fontawesome-svg-core'
// import { fas } from '@fortawesome/free-solid-svg-icons'
// import { far } from '@fortawesome/free-regular-svg-icons'

const PageContainer = tw.div`
   font-body bg-gray-100 text-xl w-full
`;

const Main = styled.main`
    min-height: calc(100vh - 477px);
`;

const Layout = ({children}) => (
    <PageContainer>
        <Header />
        <Main>
            {children}
        </Main>
        <Footer />
    </PageContainer>
);

// library.add(fas);

export default Layout;