import PropTypes from "prop-types"
import React from "react"
import tw from 'tailwind.macro';
import Navigation from './nav';

const StyledHeader  = tw.div`
  w-full bg-darkBlue text-white
`;

const Header = () => (
  <StyledHeader>
    <Navigation/>
  </StyledHeader>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
