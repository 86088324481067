import React from "react"
import tw from 'tailwind.macro';
import styled from '@emotion/styled';

 const Message = styled.strong`
  ${tw`
    rounded py-3 px-2 font-display text-sm md:text-xl text-white border-2
  `}
  opacity: .8;
 `;

const PaidFor = () => (
  <Message>
    Paid for by the Committee to Elect Joel O'Dorisio
  </Message>
)

export default PaidFor
